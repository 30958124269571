<template lang="">
    <div class="application-confirmation">
        <div>
            <img :src="applicationImage" alt="applicationImage"/>
        </div>
        <div class="application-confirmation-title w-50 text-center" v-html="successTitleText"></div>

        <p class="application-confirmation-text my-4" v-html="text"></p>
        <div>
            <st-button
            variant="primary"
            :callback="goToList"
            >{{ $t('GENERAL.BUTTON.OK') }}</st-button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: 'ApplicationConfirmation',
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            applicationImage: `${process.env.BASE_URL}media/st-application-registred-trees.png`,
            treeNumbers: 432,
            dividerFactor: 10,
        }
    },
    computed: {
        text() {
            return `${this.$t(
                        "APPLICATION.SEND_SUCCESS_TEXT",
                        { number: this.id }
                    )}`;
        },
        successTitleText() {
            return `${this.$t(
                        "APPLICATION.SEND_SUCCESS_TITLE",
                        { treeNumbers: this.treeNumbers }
                    )}`;
        },
    },
    methods: {
        goToList() {
            this.$router.push({
                    name: 'applications'
                });
        },
    },
    created() {
        const now = new Date();
        const today = moment(now).format('YYYY-MM-DD');
        const startDate = new Date('2023-12-01');
        const start = moment(startDate).format('YYYY-MM-DD');
        const diff = moment(today).diff(start, 'days');
        const multiply = Math.floor(diff / this.dividerFactor);
        this.treeNumbers = this.treeNumbers + multiply;
    }
}
</script>
<style lang="">

</style>
